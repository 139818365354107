import styled from 'styled-components'
import { color, fontSize, Media, spacing, transition } from '../../style/theme'

export const StatisticalDataTotal = styled.div`
  padding-top: ${(props) => (props.IsCN ? '300px' : '220px')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 100px 0 98px;
  > div {
    :last-child {
      display: block;
      top: ${(props) => props.imageTop}px !important;
      position: absolute;
      left: 0;
      z-index: -1;
      right: 0;
    }
  }
  ${Media.phone`
    padding-top: 40px;
    margin: 0 15px;
    >div{
      :last-child{
        display:none;
    }
  }
`}
`
export const StatisticalDataTop = styled.div`
  position: relative;
`
export const StatisticalDataTitle = styled.div`
  display: inline-block;
  font-size: ${fontSize.super};
  font-weight: bold;
  color: #fff;
  width: 100%;
  line-height: 30px;
  font-family: ${(props) => !props.IsCN && 'Lato'};
  padding-bottom: ${(props) => (!props.IsCN ? '10px' : '20px')};
  > h2 {
    font-size: ${fontSize.super};
    line-height: 60px;
  }
  > div {
    display: ${(props) => (!props.IsCN && props.lastIndex ? 'block' : 'inline-block')};
    padding-top: ${(props) => !props.IsCN && props.lastIndex && '46px'};
  }
  ${Media.phone`
    font-size: ${fontSize.large};
    padding-bottom: 0;
    >h2{
      font-size:  ${fontSize.large};
      line-height: ${spacing.large};
    }
    >div{
      padding-top: ${(props) => !props.IsCN && props.lastIndex && '20px'};
    }
  `}
`

export const StatisticalDataBottom = styled.div`
  display: flex;
  flex-direction: column;
  ${Media.phone`
    font-family: ${(props) => !props.IsCN && 'Lato'}; 
    height: ${(props) => (props.IsCN ? '2150px' : '2000px')};
    >div{
      position: absolute;
      padding-top: 21px;
      :nth-child(1){
        padding-top: ${(props) => (props.IsCN ? '140px' : '190px')};
        padding-left: 40px;
        @media(max-width:320px){
          padding-top: ${(props) => props.IsCN && '200px'};
        }
      }
    }
  `}
`

export const StatisticalDataList = styled.div`
  z-index: 3;
  position: relative;
  > div {
    display: inline-block;
    font-size: ${fontSize.base};
    font-weight: 400;
    color: #fff;
    line-height: ${spacing.large};
    > h1 {
      display: block;
      font-size: 60px;
      font-weight: bold;
      color: #fff;
      line-height: 60px;
      > span {
        font-size: ${fontSize.large};
      }
    }
  }
  ${Media.phone`
    >canvas{
      padding-top: ${(props) => (props.IsCN ? '90px' : '140px')};
      @media(max-width:320px){
        padding-top: ${(props) => props.IsCN && '150px'};
      }
    }
    >div{
      font-size: 8px;
      line-height: 15px;
      padding-right: 10px;
      padding-top: 10px;
      >h1{
        font-size: 30px;
        line-height: ${spacing.large};
        >span{
          font-size: ${fontSize.mini};
         }
        }
      }
   `}
`

export const StatisticalDataListTitle = styled.div`
  position: absolute;
  top: -12px;
  right: 0;
  width: 58%;
  z-index: 3;
  > div {
    display: inline-block;
    font-size: ${fontSize.base};
    font-weight: 400;
    color: ${color.text};
    line-height: ${spacing.large};
    padding-right: ${(props) => (!props.IsCN ? '30px' : '93px')};
    > h1 {
      display: block;
      font-size: 48px;
      font-weight: bold;
      color: ${color.text};
      line-height: 48px;
      > span {
        font-size: ${fontSize.large};
      }
    }
  }
  ${Media.phone`
    top: ${(p) => (p.IsCN ? '48px' : '60px')};
    left: 0;
    width:unset;
    >div{
      font-size: 8px;
      line-height: 15px;
      padding-right: 20px;
      :nth-child(3){
        padding-right: 0px;
        padding-top: 20px;
      }
      >h1{
        font-size: 30px;
        line-height: 36px;
        >span{
          font-size: ${fontSize.mini};
        }
      }
    }
  `}
`
export const StatisticalYearList = styled.div`
  color: #fff;
  padding-top: ${(props) => props.padTop};
  font-size: 10px;
  line-height: 15px;
  > span {
    display: block;
  }
`
export const StatisticalYearListTitle = styled.div`
  display: ${(props) => (props.titleVisible > 0 ? 'block' : 'none')};
`

export const Tooltips = styled.div`
  transition: ${transition(['width', 'top', 'left'])};
  position: absolute;
  // width: ${(props) => props.toolTipContent === '2010' && '300px'};
  left: ${(props) =>
    props.toolTipContent === '2010'
      ? props.toolTipLeft - 100
      : props.toolTipLeft - props.toolTipWidth / 2}px;
  top: ${(props) => props.toolTipHeight - 90}px;
  background: #0c0c0d;
  border-radius: 8px;
  border: 1px solid #2dbec3;
  padding-right: 0;
  font-family: ${(props) => !props.IsCN && 'Lato'};
  z-index: 10;
  > div {
    :nth-child(1) {
      font-weight: bold;
      font-size: ${fontSize.base};
      padding: 20px 20px 5px 20px;
    }
    > span {
      display: block;
    }
  }
`
export const StatisticalDataListDetail = styled.div`
  padding: 5px 20px 20px;
  font-size: 14px;
  > span {
    :nth-child(1) {
      font-weight: bold;
      font-size: ${fontSize.base};
      display: ${(props) => (props.titleVisible > 0 ? 'block' : 'none')};
    }
  }
`
export const ArrowBg = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;
  line-height: 0px;
  border-width: 0 15px 20px;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #2dbec3;
  bottom: 80px;
  right: ${(props) => props.ArrowRight};
  bottom: ${(props) => props.tooltipBom + 2}px;
  display: ${(props) => (props.tooltipYear !== -1 ? 'block' : 'none')};
`
export const ArrowBorder = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;
  line-height: 0px;
  border-width: 0 15px 20px;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #0c0c0d;
  bottom: 78px;
  right: ${(props) => props.ArrowRight};
  bottom: ${(props) => props.tooltipBom}px;
  display: ${(props) => (props.tooltipYear !== -1 ? 'block' : 'none')};
`
