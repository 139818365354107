import styled from 'styled-components'
import { color, fontSize, Media, spacing, transition } from '../../style/theme'

export const JoinUsTotal = styled.div`
  display: flex;
  margin: 0 100px 0 98px;
  margin-top: -30px;
  ${Media.phone`
    flex-direction: column;
    margin: 0 15px;
 `}
`
export const JoinUsLeft = styled.div`
  flex-grow: 1;
  padding-top: 248px;
  ${Media.phone`
    padding-top: 40px;
  `}
`
export const JoinUsContainer = styled.div`
  flex-grow: 1;
  width: 742px;
  max-width: 100%;
  ${Media.phone`
    margin: 0;
  `}
`
export const JoinUsTitle = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: ${color.text};
  line-height: 30px;
  padding-bottom: ${spacing.base};
  ${Media.phone`
    font-size: ${fontSize.large};
    line-height: 36px;
    padding-bottom: 0;
`}
`
export const JoinUsRight = styled.div`
  position: relative;
  max-width: 58%;
  ${Media.phone`
    max-width: 100%;
  `}
`

export const JoinUsContent = styled.div`
  padding-top: 237px;
  font-size: ${fontSize.base};
  font-weight: 400;
  color: #fff;
  line-height: 24px;
  position: absolute;
  top: 0px;
  margin-right: 120px;
  > div {
    transition: ${transition('color')};
    padding-top: 59px;
    svg {
      margin-left: 24px;
      g {
        transition: ${transition('stroke')};
      }
    }
    :hover {
      svg g {
        stroke: ${color.primaryDark};
      }
      > a {
        display: inline-block;
        :hover {
          color: ${color.primaryDark};
        }
      }
    }
    > div {
      max-width: 152px;
      margin-top: -5px;
      margin-left: 24px;
    }
  }
  @media (max-width: 1600px) {
    padding-top: 137px;
  }
  ${Media.phone`
    width: 100%;
    font-size: 10px;
    line-height: 15px;
    right:0;
    padding-top: 29px;
    margin-right: 0;
    margin-left: 0;
    >div{
      padding-top: 40px;
      span:nth-child(1){
        font-weight: bold;  
        display: inline-block;
        padding-right: 19px;
      }
      :hover {
        svg g {
          stroke: ${color.primaryDark};
        }
      }
      >a{
        >span{
          margin-right: 12px;
          :hover {
            color: ${color.primaryDark};
          }
        }
      }
    }  
  `}
`
