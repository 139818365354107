import styled from 'styled-components'
import { color, fontSize, Media, shape } from '../../style/theme'

export const TechnicalCapabilityTotal = styled.div`
  display: flex;
  margin: 0 100px 0 99px;
  padding-top: 222px;
  font-family: ${(props) => !props.IsCN && 'Lato'};
  position: ${(props) => !props.IsCN && 'relative'};
  ${Media.phone`
    padding-top:80px;
    flex-direction: column;
    margin: 0 15px;
  `}
`
export const TechnicalCapabilityLeft = styled.div`
  display: inline-block;
  width: 447px;
  flex-grow: 1;
  ${Media.phone`
    width: 100%;
  `}
`
export const TechnicalCapabilityTitle = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: ${color.text};
  line-height: ${(props) => (props.IsCN ? '30px' : '60px')};
  padding-bottom: ${(props) => (props.IsCN ? '20px' : '10px')};
  margin: 0 200px 0 0;
  ${Media.phone`
    font-size: ${fontSize.large};
    line-height: 36px;
    margin: 0;
    padding-bottom: 0px;
    max-width: ${(p) => (!p.phoneModel ? '300px' : 'unset')};
  `}
`
export const TechnicalCapabilityContainer = styled.div`
  width: 742px;
  max-width: 100%;
  flex-grow: 1;
  ${Media.phone`
    margin: 0;
  `}
`
export const TechnicalCapabilityRight = styled.div`
  flex-shrink: 0;
  width: 58%;
  display: flex;
  flex-wrap: wrap;
  ${Media.phone`
    padding-top: 35px;
    width: 100%;
  `}
`
export const TechnicalSkillBlock = styled.div`
  width: ${(p) => (p.phoneModel ? '100%' : '480px')};
  height: ${(p) => (p.IsCN ? '220px' : '280px')};
  padding: 40px 30px;
  background: rgba(34, 37, 43, 0.6);
  border-radius: ${shape.radiusBase};
  color: ${color.text};
  &:first-child {
    margin: 0 20px 20px 0;
  }
  &:last-child {
    margin-left: 20px;
  }
  &:hover {
    box-shadow: 0px 10px 18px 2px rgba(34, 37, 43, 0.5);
  }
  ${Media.phone`
  height:auto;
  padding: 15px;
  margin-bottom:15px;
  &:first-child {
    margin: 0;
    margin-bottom:15px;
  }
  &:last-child {
    margin-left: 0;
  }
  `}
`
export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  h3 {
    font-size: ${fontSize.large};
    font-weight: bold;
    color: ${color.text};
    font-family: ${(props) => !props.IsCN && 'Lato'};
    line-height: ${(props) => (props.IsCN ? '24px' : '30px')};
  }
  p {
    padding-top: 18px;
    font-size: 14px;
    font-weight: 400;
    color: ${color.text};
    line-height: 24px;
  }
  ${Media.phone`
  justify-content:space-between;
    h3{
      font-size: 13px;
    line-height: ${(props) => (props.IsCN ? '12px' : '15px')};
  }
    p{
      font-size: 12px;
      padding-top: 10px;
      line-height: 15px;
  }
  `}
`
export const InfoWrapper = styled.div`
  ${Media.phone`
    width: ${(p) => (p.IsCN ? '75%' : '90%')};
  `}
`
export const SkillEn = styled.div`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  color: ${color.text};
  line-height: 24px;
  font-family: Lato;
  ${Media.phone`
  margin-top: 12px;
  font-size: 12px;
  line-height: 12px;
`}
`
export const IconWrapper = styled.div`
  margin-right: 30px;
  ${Media.phone`
  margin-right:0;
  display:flex;
  align-items:center;
`}
`
export const TechnicalCapabilityList = styled.div`
  background: #2f2f2f;
  width: 310px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: ${shape.radiusBase};
  color: ${color.text};
  padding-left: 30px;
  padding-right: 26px;
  > div {
    padding: 41px 16px 39px;
    width: 130px;
  }
  > h3 {
    font-size: ${fontSize.large};
    font-weight: bold;
    color: ${color.text};
    font-family: ${(props) => !props.IsCN && 'Lato'};
    line-height: ${(props) => (props.IsCN ? '24px' : '30px')};
  }
  > span {
    display: block;
    padding-top: 18px;
    font-size: 14px;
    font-weight: 400;
    color: ${color.text};
    line-height: 24px;
  }
  @media (max-width: 1600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: 1400px) {
    margin-bottom: 20px;
  }
  ${Media.phone`
    width: 170px;
    height: 195px;
    padding-left: 15px;
    padding-right: 14px;
    margin-right: 0px;
    font-size: 8px;
    font-family: Source Han Sans CN, Source Han Sans SC, Lato;
    >div{
      padding: 22px 8px 21px;
      max-width: 70px;
    }
    >h2{
      font-size: 13px;
      line-height: 12px;
    }
    >span{
      display:block;
      padding-top: 9px;
      font-size: 8px;
      line-height: 12px;
    }
    >h3{
      font-size: ${fontSize.mini};
      line-height: 12px;
    }
  `}
`
