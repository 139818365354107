import styled from 'styled-components'
import { color, fontSize, Media, spacing } from '../../style/theme'

export const QualificationTotal = styled.div`
  padding-top: 140px;
  display: flex;
  justify-content: space-between;
  margin: 0 100px 0 98px;
  @media (min-width: 1920) {
    justify-content: stretch;
  }
  ${Media.phone`
    padding-top:40px;
    flex-direction: column;
    margin: 0 15px;
  `}
`
export const QualificationLeft = styled.div`
  padding-top: 71px;
  flex-grow: 1;
  padding-top: ${(props) => !props.IsCN && '0'};
  ${Media.phone`
    padding-top: 0;
    margin: 0 ;
 `}
`

export const QualificationContent = styled.div`
  flex-grow: 1;
`
export const QualificationTitle = styled.div`
  display: inline-block;
  font-size: ${fontSize.super};
  font-weight: bold;
  color: ${color.text};
  padding-bottom: ${spacing.base};
  line-height: 30px;
  font-family: ${(props) => !props.IsCN && 'Lato'};
  padding-bottom: ${(props) => !props.IsCN && '10px'};
  > h1 {
    font-size: ${fontSize.super};
    font-weight: bold;
    color: ${color.text};
    line-height: 60px;
  }
  ${Media.phone`
    font-size: ${fontSize.large};
    line-height: 30px;
    >h1{
      font-size: ${fontSize.large};
      line-height: 36px;
      padding-bottom: 0;
    }
  `}
`
export const QualificationRight = styled.div`
  flex-shrink: 0;
  max-width: 58%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${Media.phone`
    padding-top: 30px;
    max-width: 100%;
    flex-direction:${(p) => !p.IsCN && 'column'};
  `}
`

export const QualificationList = styled.div`
  display: inline-block;
  width: 319px;
  height: 80px;
  max-width: 50%;
  background: rgba(34, 37, 43, 0.6);
  border-radius: 10px;
  margin-bottom: 21px;
  color: ${color.text};
  @media (min-width: 1920) {
    margin-right: 24px;
  }
  > div {
    font-family: ${(props) => !props.IsCN && 'Lato'};
    padding-right: ${(props) => !props.IsCN && '0'};
    padding: 20px 19px;
    :nth-child(1) {
      padding-right: 0px;
    }
    display: flex;
    align-items: center;
    > div {
      width: 47px;
      > svg {
        display: block;
        margin: 0 auto;
      }
    }
    > span {
      width: 252px;
      display: inline-block;
      vertical-align: middle;
      padding-left: 12px;
      font-size: ${(props) => (props.IsCN ? '18px' : '16px')};
    }
  }

  ${Media.phone`
    width: ${(p) => (!p.IsCN ? '100%' : '170px')};
    height: 40px;
    margin-bottom: 15px;
    border-radius: 5px;
    max-width:${(p) => !p.IsCN && '100%'};
    >div{
      padding: 10px 0px 10px 11px;
      >div{
        width: 24px;
      }
      >span{
        font-size: ${(props) => (props.IsCN ? '10px' : '8px')};
        width: ${(p) => (!p.IsCN ? 'auto' : '252px')};
        padding-left: 8px;
        flex-shrink: 2;
      }
    }
  `}
`
