import styled from 'styled-components'
import { fontSize, Media, spacing } from '../../style/theme'

export const ContactUsTotal = styled.div`
  display: flex;
  margin-top: 150px;
  margin-right: 100px;
  margin-left: 98px;
  font-family: ${(props) => !props.IsCN && 'Lato'};
  ${Media.phone`
    padding-top: 80px;
    flex-direction: column;
    margin: 0 15px;
`}
`
export const ContactUsContainer = styled.div`
  flex-grow: 1;
  width: 742px;
  ${Media.phone`
    margin: 0;
    width: 100%;
`}
`
export const ContactUsLeft = styled.div`
  display: inline-block;
  flex-grow: 1;
`
export const ContactUsTitle = styled.div`
  display: inline-block;
  font-size: ${fontSize.super};
  font-weight: bold;
  color: #fff;
  width: 100%;
  line-height: ${spacing.large};
  padding-bottom: ${spacing.base};
  ${Media.phone`
    font-size: ${fontSize.large};
    line-height: 36px;
    padding-bottom: 0;
`}
`
export const ContanctUsContent = styled.div`
  padding-top: ${(p) => (!p.IsCN ? '0' : '43px')};
  font-size: ${fontSize.medium};
  font-weight: 400;
  color: #fff;
  line-height: 30px;
  > span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    :nth-child(1) {
      padding-top: 18px;
    }
  }
  ${Media.phone`
    padding-top:28px;
    font-size: 15px;
    line-height: 15px;
    padding-bottom: 0;
    width: 100%;
    >span{
      font-size: 10px;
      line-height: 15px;
      :nth-child(1){
        padding-top: 7px;
      }
    }
`}
`
export const ContactUsRight = styled.div`
  flex-shrink: 0;
  width: 58%;
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  ${Media.phone`
      padding-top: 30px;
      width: 100%;
  `}
`

export const ContactUsList = styled.div`
  display: inline-block;
  font-size: ${fontSize.medium};
  font-weight: bold;
  color: #fff;
  line-height: 30px;
  width: 33.3%;
  :last-child {
    margin-right: 0px;
  }
  padding-bottom: 60px;
  h5 {
    word-wrap: break-word;
    white-space: normal;
  }
  > span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    :nth-child(1) {
      padding-top: 12px;
    }
  }
  ${Media.phone`
    font-size: ${fontSize.mini};
    line-height: 15px;
    margin-right: 15px;
    width: 155px;
    :nth-child(2){
      margin-right: 0;
    }
    padding-bottom: 32px;
    >span{
      display: block;
      font-size: ${fontSize.tiny};
      line-height: 15px;
      :nth-child(1){
        padding-top: 6px;
      }
    }
  `}
`

export const ContactUsList1 = styled.div`
  display: inline-block;
  font-size: ${fontSize.medium};
  font-weight: bold;
  color: #fff;
  line-height: 30px;
  width: 33.3%;
  box-sizing: border-box;
  padding-right: 50px;
  :last-child {
    margin-right: 0px;
  }
  > span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    :nth-child(1) {
      padding-top: 12px;
    }
  }
  ${Media.phone`
    font-size: ${fontSize.mini};
    line-height: 15px;
    width: 50%;
    padding-bottom: 32px;
    padding-right: 0px;
    :nth-child(3){
      width: 100%;
      padding-bottom: 0;
    }
    >span{
      display: block;
      font-size: 10px;
      line-height: 15px;
      :nth-child(1){
        padding-top: 6px;
      }
    }
`}
`
