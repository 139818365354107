import styled from 'styled-components'
import { fontSize, Media, spacing, color, shape } from '../../style/theme'

export const IntroduceDetailContent = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: ${shape.radiusBase};
  margin-top: 379px;
  flex-shrink: 0;
  color: ${color.text};
  max-width: 58%;
  ${Media.phone`
      margin: 25px 0 0 0;
      max-width: 100%;
  `}
`
export const IntroduceDetailContentTitle = styled.div`
  padding: 60px 0 29px 49px;
  > h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    font-family: ${(props) => !props.IsCN && 'Lato'};
  }
  ${Media.phone`
    padding: 28px 15px 14px 15px;
    >h2{
      font-size: ${fontSize.medium};
      line-height: ${spacing.base};
    }
`}
`
export const IntroduceDetailContentList = styled.div`
  font-size: ${fontSize.base};
  font-weight: 400;
  line-height: 24px;
  padding-left: 49px;
  padding-right: 55px;
  font-family: ${(props) => !props.IsCN && 'Lato'};
  ${Media.phone`
    font-size: ${fontSize.tiny};
    line-height: 18px;
    padding-left: 15px;
    padding-right: 29px;
`}
`
