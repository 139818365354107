import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-react-intl'
import {
  QualificationTotal,
  QualificationLeft,
  QualificationRight,
  QualificationTitle,
  QualificationList,
  QualificationContent,
} from './qualification.atom'
import { TitleBarEN, TitleBarCN } from './title-barline.atom'
import {
  AboutQualificationIcon1,
  AboutQualificationIcon2,
  AboutQualificationIcon3,
  AboutQualificationIcon4,
  AboutQualificationIcon5,
  AboutQualificationIcon6,
} from '../icons'

const Qualification = ({ phoneModel = false, IsCN = true }) => {
  const qualificationList = [
    {
      icon: <AboutQualificationIcon1 style={{ width: '47px' }} />,
      iconm: <AboutQualificationIcon1 style={{ width: '24px' }} />,
      content: 'about.secondContent_1',
      contentm: 'about.secondContent_1',
    },
    {
      icon: <AboutQualificationIcon2 style={{ width: '28px' }} />,
      iconm: <AboutQualificationIcon2 style={{ width: '14px' }} />,
      content: 'about.secondContent_2',
      contentm: 'about.secondContent_2',
    },
    {
      icon: <AboutQualificationIcon3 style={{ width: '46px' }} />,
      iconm: <AboutQualificationIcon3 style={{ width: '23px' }} />,
      content: 'about.secondContent_3',
      contentm: 'about.secondContent_3',
    },
    {
      icon: <AboutQualificationIcon4 style={{ width: '39px' }} />,
      iconm: <AboutQualificationIcon4 style={{ width: '17px' }} />,
      content: 'about.secondContent_4',
      contentm: 'about.secondContent_4',
    },
    {
      icon: <AboutQualificationIcon5 style={{ width: '40px' }} />,
      iconm: <AboutQualificationIcon5 style={{ width: '20px' }} />,
      content: 'about.secondContent_5',
      contentm: 'about.secondContent_5',
    },
    {
      icon: <AboutQualificationIcon6 style={{ width: '18px' }} />,
      iconm: <AboutQualificationIcon6 style={{ width: '9px' }} />,
      content: 'about.secondContent_6',
      contentm: 'about.secondContent_6',
    },
  ]
  return (
    <QualificationTotal>
      <QualificationLeft>
        <QualificationContent>
          <QualificationTitle IsCN={IsCN}>
            {IsCN ? (
              <FormattedMessage id='about.secondTitle' />
            ) : (
              <h1>
                RAYVISION {!phoneModel && <br />}
                QUALIFICATIONS
              </h1>
            )}
            {IsCN ? <TitleBarCN fristBar={40} secondBar={140} /> : <TitleBarEN />}
          </QualificationTitle>
        </QualificationContent>
      </QualificationLeft>
      <QualificationRight IsCN={IsCN}>
        {qualificationList.map((item, index) => {
          return (
            <QualificationList IsCN={IsCN} key={index}>
              <div>
                <div>{phoneModel ? item.iconm : item.icon}</div>
                <span>
                  <FormattedMessage id={phoneModel ? item.contentm : item.content} />
                </span>
              </div>
            </QualificationList>
          )
        })}
      </QualificationRight>
    </QualificationTotal>
  )
}

export default Qualification
