import * as React from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl'
import {
  JoinUsTotal,
  JoinUsLeft,
  JoinUsRight,
  JoinUsTitle,
  JoinUsContent,
  JoinUsContainer,
} from './join-us.atom'
import { TitleBarCN } from './title-barline.atom'
import { StaticImage } from 'gatsby-plugin-image'
import { GoIcon } from '../icons'

const JoinUs = () => {
  return (
    <JoinUsTotal>
      <JoinUsContainer>
        <JoinUsLeft>
          <JoinUsTitle>
            <FormattedMessage id='about.fifthTitle' />
          </JoinUsTitle>
          <TitleBarCN fristBar={40} secondBar={140} />
        </JoinUsLeft>
      </JoinUsContainer>
      <JoinUsRight>
        <StaticImage style={{ zIndex: '-1' }} alt='' src='../../images/about/about_bg3.png' />
        <JoinUsContent>
          <FormattedMessage id='about.fifthContent_1' />
          <br />
          <br />
          <FormattedMessage id='about.fifthContent_2' />
          <div>
            <Link to='https://rayvision.zhiye.com/' rel='nofollow'>
              <FormattedMessage id='about.fifthContent_3' />
              <GoIcon style={{ width: '114px', verticalAlign: 'middle' }} />
            </Link>
          </div>
        </JoinUsContent>
      </JoinUsRight>
    </JoinUsTotal>
  )
}

export default JoinUs
