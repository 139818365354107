import styled from 'styled-components'
import { color, fontSize, Media } from '../theme'

export const AboutPage = styled.div`
  background: #162735;
`

export const AboutPageContainer = styled.div`
  position: relative;
  padding-bottom: 60px;
`
export const HeadBanner = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  > div {
    :nth-child(2) {
      display: flex;
      margin: 0 100px 0 98px;
    }
  }
  ${Media.phone`
   flex-direction: column;
   >div{
    :nth-child(1){
      margin: 0;
    }
    :nth-child(2){
      flex-direction: column;
      margin: 0 15px 0 15px;
    }
   }
`}
`
export const HeadBannerContainer = styled.div`
  width: 742px;
  max-width: 100%;
  flex-grow: 1;
  ${Media.phone`
    flex-direction: column;
`}
`

export const HeaderBannerTitle = styled.div`
  padding-top: 451px;
  flex-grow: 1;
  > h1 {
    font-size: ${fontSize.super};
    font-weight: bold;
    color: ${color.text};
    line-height: 72px;
    font-family: ${(props) => !props.IsCN && 'Lato'};
  }
  ${Media.phone`
    padding-top:160px;
    line-height: 36px;
    padding-top:160px;
    padding-right: 0;
    >h1{
      font-size: ${fontSize.large};
      line-height: 36px;
    }
  `}
`
export const SecondBanner = styled.div`
  z-index: 1;
  position: relative;
  margin-bottom: 120px;
  ${Media.phone`
    margin-bottom: 100px;
`}
`
