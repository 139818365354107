import { FormattedMessage } from 'gatsby-plugin-react-intl'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useRef, useEffect } from 'react'
import {
  StatisticalDataTotal,
  StatisticalDataTop,
  StatisticalDataTitle,
  StatisticalDataBottom,
  StatisticalDataList,
  StatisticalYearList,
  StatisticalDataListTitle,
  StatisticalDataListDetail,
  StatisticalYearListTitle,
  Tooltips,
  ArrowBg,
  ArrowBorder,
} from './statistical-data.atom'
import { TitleBarEN, TitleBarCN } from './title-barline.atom'

const DataListCN = [
  { title: 'about.statistical_data_title_1', describe: 'about.statistical_data_desc_1' },
  { title: 'about.statistical_data_title_2', describe: 'about.statistical_data_desc_2' },
  { title: 'about.statistical_data_title_3', describe: 'about.statistical_data_desc_3' },
]

const YearDataList = [
  {
    content: '2022',
    totallength: 7,
    titleLength: 1,
    top: '0',
    title: [],
    contentList: [],
    width: 230,
    right: '28%',
  },
  {
    content: '2021',
    totallength: 10,
    titleLength: 0,
    top: '40px',
    title: [],
    contentList: [],
    width: 180,
    right: '43%',
  },
  {
    content: '2020',
    totallength: 11,
    titleLength: 2,
    top: '40px',
    title: [],
    contentList: [],
    width: 340,
    right: '45%',
  },
  {
    content: '2019',
    totallength: 14,
    titleLength: 4,
    top: '40px',
    title: [],
    contentList: [],
    width: 220,
    right: '45%',
  },
  {
    content: '2018',
    totallength: 14,
    titleLength: 2,
    top: '40px',
    title: [],
    contentList: [],
    width: 196,
    right: '45%',
  },
  {
    content: '2017',
    totallength: 9,
    titleLength: 3,
    top: '40px',
    title: [],
    contentList: [],
    width: 249,
    right: '45%',
  },
  {
    content: '2016',
    totallength: 9,
    titleLength: 4,
    top: '40px',
    title: [],
    contentList: [],
    width: 173,
    right: '40%',
  },
  {
    content: '2015',
    totallength: 5,
    titleLength: 0,
    top: '40px',
    title: [],
    contentList: [],
    width: 136,
    right: '40%',
  },
  {
    content: '2014',
    totallength: 5,
    titleLength: 0,
    top: '40px',
    title: [],
    contentList: [],
    width: 138,
    right: '40%',
  },
  {
    content: '2013',
    totallength: 3,
    titleLength: 0,
    top: '40px',
    title: [],
    contentList: [],
    width: 122,
    right: '41%',
  },
  {
    content: '2012',
    totallength: 1,
    titleLength: 1,
    top: '40px',
    title: [],
    contentList: [],
    width: 168,
    right: '41%',
  },
  {
    content: '2011',
    totallength: 4,
    titleLength: 2,
    top: '40px',
    title: [],
    contentList: [],
    width: 202,
    right: '43%',
  },
  {
    content: '2010',
    totallength: 2,
    titleLength: 2,
    top: '40px',
    title: [],
    contentList: [],
    width: 168,
    right: '33%',
  },
]
const YearDataListEN = [
  {
    content: '2022',
    totallength: 3,
    titleLength: 0,
    top: '0px',
    title: [],
    contentList: [],
    width: 300,
    right: '13%',
  },
  {
    content: '2021',
    totallength: 10,
    titleLength: 0,
    top: '40px',
    title: [],
    contentList: [],
    width: 332,
    right: '45%',
  },
  {
    content: '2020',
    totallength: 11,
    titleLength: 2,
    top: '36px',
    title: [],
    contentList: [],
    width: 384,
    right: '45%',
  },
  {
    content: '2019',
    totallength: 14,
    titleLength: 3,
    top: '40px',
    title: [],
    contentList: [],
    width: 366,
    right: '44%',
  },
  {
    content: '2018',
    totallength: 14,
    titleLength: 2,
    top: '40px',
    title: [],
    contentList: [],
    width: 320,
    right: '45%',
  },
  {
    content: '2017',
    totallength: 9,
    titleLength: 3,
    top: '36px',
    title: [],
    contentList: [],
    width: 380,
    right: '45%',
  },
  {
    content: '2016',
    totallength: 9,
    titleLength: 4,
    top: '30px',
    title: [],
    contentList: [],
    width: 360,
    right: '45%',
  },
  {
    content: '2015',
    totallength: 5,
    titleLength: 0,
    top: '30px',
    title: [],
    contentList: [],
    width: 226,
    right: '43%',
  },
  {
    content: '2014',
    totallength: 5,
    titleLength: 0,
    top: '30px',
    title: [],
    contentList: [],
    width: 155,
    right: '40%',
  },
  {
    content: '2013',
    totallength: 3,
    titleLength: 0,
    top: '30px',
    title: [],
    contentList: [],
    width: 107,
    right: '36%',
  },
  {
    content: '2012',
    totallength: 1,
    titleLength: 1,
    top: '28px',
    title: [],
    contentList: [],
    width: 384,
    right: '45%',
  },
  {
    content: '2011',
    totallength: 3,
    titleLength: 1,
    top: '32px',
    title: [],
    contentList: [],
    width: 217,
    right: '62%',
  },
  {
    content: '2010',
    totallength: 1,
    titleLength: 1,
    top: '26px',
    title: [],
    contentList: [],
    width: 410,
    right: '72%',
  },
]

for (let i = 0; i < YearDataList.length; i++) {
  for (let j = 0; j < YearDataList[i].totallength; j++) {
    if (j < YearDataList[i].titleLength) {
      if (YearDataList[i].content !== '2022')
        YearDataList[i].title.push(
          'about.statistical_datalist_' + YearDataList[i].content + '_' + j,
        )
    } else {
      YearDataList[i].contentList.push(
        'about.statistical_datalist_' + YearDataList[i].content + '_' + j,
      )
    }
  }
}

for (let i = 0; i < YearDataListEN.length; i++) {
  for (let j = 0; j < YearDataListEN[i].totallength; j++) {
    if (j < YearDataListEN[i].titleLength) {
      YearDataListEN[i].title.push(
        'about.statistical_datalist_' + YearDataListEN[i].content + '_' + j,
      )
    } else {
      YearDataListEN[i].contentList.push(
        'about.statistical_datalist_' + YearDataListEN[i].content + '_' + j,
      )
    }
  }
}
const yArr = [
  142,
  179,
  203,
  211,
  276,
  293,
  325,
  327,
  353,
  368,
  370,
  389,
  395,
  430,
  455,
  468,
  508,
  522,
  533,
  562,
  601,
  649,
]
const xArr = [
  40,
  153,
  278,
  417,
  484,
  551,
  619,
  686,
  750,
  818,
  888,
  958,
  1028,
  1098,
  1164,
  1229,
  1297,
  1365,
  1432,
  1498,
  1562,
  1633,
]
const tooltip = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022]
const yArrMbCN = [10, 175, 390, 600, 880, 1150, 1355, 1550, 1686, 1820, 1925, 1985, 2105]
const yArrMbEN = [10, 115, 330, 545, 830, 1110, 1305, 1495, 1625, 1755, 1845, 1895, 1990]
const tooltipm = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010]

const normalClientWidth = 1920
const normalClientHeight = 666

let xArrScale = [...xArr]
let yArrScale = [...yArr]

const StatisticalData = ({ phoneModel = false, IsCN = true }) => {
  const contentDataList = IsCN ? YearDataList : YearDataListEN
  const yArrm = IsCN ? yArrMbCN : yArrMbEN
  const [showHeight, setTop] = useState(0)
  const [showWidth, setLeft] = useState(0)
  const [showYear, setYear] = useState(-1)
  const [showBottom, setBottom] = useState(0)
  const [resizeCanvasWidth, setResizeCanvasWidth] = useState(0)
  const [resizeCanvasHeight, setResizeCanvasHeight] = useState(666)
  const [resizeCanvasPadding, setResizeCanvasPadding] = useState(280)
  const [resizeCanvasScale, setResizeCanvasScale] = useState(1)

  const staticalDataPage = useRef(null)

  const handleResize = (event) => {
    if (staticalDataPage.current) {
      setResizeCanvasWidth(staticalDataPage.current.clientWidth)
      drawLine()
    }
  }
  // 获取鼠标放置的位置
  const getMousePos = (canvas, evt) => {
    var rect = canvas.getBoundingClientRect()
    return {
      x: evt.clientX - rect.left * (canvas.width / rect.width),
      y: evt.clientY - rect.top * (canvas.height / rect.height),
    }
  }
  useEffect(() => {
    if (staticalDataPage.current) {
      setResizeCanvasWidth(staticalDataPage.current.clientWidth)
      setResizeCanvasHeight(
        (window.innerWidth < normalClientWidth
          ? staticalDataPage.current.clientWidth / normalClientWidth
          : 1) * normalClientHeight,
      )
      setResizeCanvasPadding(
        Math.abs(
          (staticalDataPage.current.clientWidth / normalClientWidth) * normalClientHeight -
            normalClientHeight,
        ) + 280,
      )
      drawLine()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [resizeCanvasWidth]) // eslint-disable-line react-hooks/exhaustive-deps

  const drawLine = () => {
    let i = 0
    var c = document.getElementById('myCanvas')
    var cxt = c.getContext('2d')

    setResizeCanvasScale(
      window.innerWidth < normalClientWidth ? resizeCanvasWidth / normalClientWidth : 1,
    )
    let scaleRate =
      window.innerWidth < normalClientWidth ? resizeCanvasWidth / normalClientWidth : 1
    //坐标都是以屏幕宽度为1920px进行设定的，故以1920为标准进行折线图的缩放。
    if (resizeCanvasScale !== 1) {
      for (let j = 0, len = yArr.length; j < len; j++) {
        xArrScale[j] = (xArr[j] * scaleRate).toFixed(2)
        yArrScale[j] = (yArr[j] * scaleRate).toFixed(2) - 80
      }
    } else {
      for (let j = 0, len = yArr.length; j < len; j++) {
        xArrScale[j] = (xArr[j] * scaleRate).toFixed(2)
        yArrScale[j] = (yArr[j] * scaleRate).toFixed(2) - 100
      }
    }
    if (phoneModel) {
      const dpr = window.devicePixelRatio || 1
      const { width: w, height: h } = c.getBoundingClientRect()
      c.width = w * dpr
      c.height = h * dpr
      c.style.width = w + 'px'
      c.style.height = h + 'px'
      cxt.scale(dpr, dpr)
      for (let j = 0, len = yArrm.length; j < len; j++) {
        cxt.beginPath()
        cxt.strokeStyle = '#00c5de'
        cxt.lineWidth = 2
        cxt.moveTo(22, yArrm[j])
        cxt.lineTo(22, yArrm[j + 1])
        cxt.stroke()

        cxt.beginPath()
        cxt.fillStyle = '#00c5de'
        cxt.arc(22, yArrm[j], 8, 0, Math.PI * 2, true)
        cxt.fill()
        cxt.closePath()

        cxt.beginPath()
        cxt.fillStyle = '#fff'
        cxt.arc(22, yArrm[j], 6, 0, Math.PI * 2, true)
        cxt.fill()
        cxt.closePath()
        cxt.fillStyle = '#00c5de'

        cxt.font = 'bold 16px Verdana '
        cxt.fillText(tooltipm[i++], 41, yArrm[j] + 6)
      }
    } else {
      for (let j = 0, len = yArr.length; j < len; j++) {
        cxt.beginPath()
        cxt.strokeStyle = '#00c5de'
        cxt.lineWidth = 2
        cxt.moveTo(xArrScale[j], yArrScale[yArrScale.length - 1 - j])
        cxt.lineTo(xArrScale[j + 1], yArrScale[yArrScale.length - 2 - j])
        cxt.stroke()
        //前4年折线趋势为直线，只需两个坐标绘制，后面的年份中间为折线，要用三个坐标绘制且中间坐标样式不显示圆圈
        if (j < 4 || (j % 2 === 1 && j > 3)) {
          cxt.beginPath()
          cxt.fillStyle = '#fff'
          cxt.arc(xArrScale[j], yArrScale[yArrScale.length - 1 - j], 9, 0, Math.PI * 2, true)
          cxt.fill()
          cxt.closePath()
          cxt.font = '20px Verdana'
          cxt.fillText(tooltip[i++], xArrScale[j] - 30, yArrScale[yArrScale.length - 1 - j] - 20)
        }
      }
      c.addEventListener(
        'mousemove',
        function (event) {
          if (resizeCanvasWidth !== 0) {
            let i1 = 0
            let mousePos = getMousePos(c, event)
            let pagex = mousePos.x,
              pagey = mousePos.y

            for (let j = 0, len = yArr.length; j < len; j++) {
              c.style.cursor = 'pointer'
              //判断鼠标坐标在圆圈坐标内
              if (j < 4 || (j % 2 === 1 && j > 3)) {
                if (
                  pagex > xArrScale[j] - 15 &&
                  pagex < xArrScale[j] + 15 &&
                  pagey > yArrScale[yArrScale.length - 1 - j] - 39 &&
                  pagey <
                    yArrScale[yArrScale.length - 1 - j] + (resizeCanvasScale !== 1 ? 160 : 200) + 9
                ) {
                  //设置tooltip显示位置
                  setLeft(xArrScale[j])
                  setTop(yArrScale[yArrScale.length - 1 - j] + 140)
                  setYear(i1)
                  if (document.getElementById('tooltip')) {
                    setBottom(document.getElementById('tooltip').clientHeight)
                  }

                  cxt.beginPath()
                  cxt.fillStyle = '#2DBEC3'
                  cxt.arc(
                    xArrScale[j],
                    yArrScale[yArrScale.length - 1 - j],
                    9,
                    0,
                    Math.PI * 2,
                    true,
                  )
                  cxt.fill()
                  cxt.closePath()

                  cxt.beginPath()
                  cxt.fillStyle = 'black'
                  cxt.arc(
                    xArrScale[j],
                    yArrScale[yArrScale.length - 1 - j],
                    6,
                    0,
                    Math.PI * 2,
                    true,
                  )
                  cxt.fill()
                  cxt.closePath()

                  //先清除字体内容，避免绘字重影
                  cxt.clearRect(xArrScale[j] - 30, yArrScale[yArrScale.length - 1 - j] - 50, 70, 30)
                  cxt.font = '20px Verdana'
                  cxt.fillStyle = '#2DBEC3'
                  cxt.fillText(
                    tooltip[i1++],
                    xArrScale[j] - 30,
                    yArrScale[yArrScale.length - 1 - j] - 20,
                  )
                } else {
                  //坐标不在圆圈里的，即鼠标移开圆圈时，圆圈样式恢复原状
                  c.style.cursor = 'default'
                  if (j < 4 || (j % 2 === 1 && j > 3)) {
                    cxt.beginPath()
                    cxt.fillStyle = '#fff'
                    cxt.arc(
                      xArrScale[j],
                      yArrScale[yArrScale.length - 1 - j],
                      9,
                      0,
                      Math.PI * 2,
                      true,
                    )
                    cxt.fill()
                    cxt.closePath()

                    cxt.clearRect(
                      xArrScale[j] - 30,
                      yArrScale[yArrScale.length - 1 - j] - 50,
                      70,
                      30,
                    )
                    cxt.font = '20px Verdana'
                    cxt.fillText(
                      tooltip[i1++],
                      xArrScale[j] - 30,
                      yArrScale[yArrScale.length - 1 - j] - 20,
                    )
                  }
                }
              }
            }
          }
        },
        true,
      )
    }
  }

  return (
    <StatisticalDataTotal imageTop={resizeCanvasPadding} ref={staticalDataPage}>
      <StatisticalDataTop>
        <div>
          <StatisticalDataTitle IsCN={IsCN}>
            {IsCN ? (
              <FormattedMessage id='about.statisticalData_title' />
            ) : (
              <h2>
                RAYVISION
                {!phoneModel && <br />}
                FACTS & FIGURES
              </h2>
            )}
          </StatisticalDataTitle>
          {IsCN ? <TitleBarCN fristBar={40} secondBar={140} /> : <TitleBarEN />}
        </div>
        <StatisticalDataListTitle IsCN={IsCN}>
          {DataListCN.map((item, index) => {
            return (
              <div lastIndex={index === DataListCN.length - 1} key={index}>
                <h1>
                  <FormattedMessage id={item.title} />
                  <span>{IsCN ? (index === 0 ? ' +' : ' 万+') : ''}</span>
                </h1>
                <FormattedMessage id={item.describe} />
              </div>
            )
          })}
        </StatisticalDataListTitle>
      </StatisticalDataTop>
      <StatisticalDataBottom IsCN={IsCN}>
        {phoneModel && (
          <div>
            {contentDataList.map((item, index) => {
              return (
                <StatisticalYearList padTop={item.top} key={index}>
                  {item.title.map((item1, index1) => {
                    return (
                      <span key={index1}>
                        <FormattedMessage id={item1} key={index} />
                      </span>
                    )
                  })}
                  <StatisticalYearListTitle titleVisible={item.totallength - item.titleLength}>
                    <FormattedMessage id='about.statistical_project_title'></FormattedMessage>
                  </StatisticalYearListTitle>
                  {item.contentList.map((item1, index1) => {
                    return (
                      <span key={index1}>
                        <FormattedMessage id={item1} />
                      </span>
                    )
                  })}
                </StatisticalYearList>
              )
            })}
          </div>
        )}

        <StatisticalDataList IsCN={IsCN}>
          <canvas
            id='myCanvas'
            width={resizeCanvasWidth}
            height={phoneModel ? '2180' : resizeCanvasHeight}
          />

          {!phoneModel && showYear !== -1 && (
            <Tooltips
              id='tooltip'
              toolTipHeight={showHeight}
              toolTipContent={contentDataList[YearDataList.length - 1 - showYear].content}
              toolTipWidth={contentDataList[YearDataList.length - 1 - showYear].width}
              toolTipLeft={showWidth}
              IsCN={IsCN}
            >
              {contentDataList.map((item, index) => {
                return (
                  index === YearDataList.length - 1 - showYear && (
                    <div key={index}>
                      {item.title.map((item1, index1) => {
                        return (
                          <span key={index1}>
                            <FormattedMessage id={item1} />
                          </span>
                        )
                      })}
                    </div>
                  )
                )
              })}
              {contentDataList.map((item, index) => {
                return (
                  index === YearDataList.length - 1 - showYear && (
                    <StatisticalDataListDetail
                      titleVisible={item.totallength - item.titleLength}
                      key={index}
                    >
                      <span>
                        <FormattedMessage id='about.statistical_project_title'></FormattedMessage>
                      </span>
                      {item.contentList.map((item1, index1) => {
                        return (
                          <span key={index1}>
                            <FormattedMessage id={item1} />
                          </span>
                        )
                      })}
                    </StatisticalDataListDetail>
                  )
                )
              })}
              <ArrowBg
                tooltipBom={showBottom}
                tooltipYear={showYear}
                ArrowRight={contentDataList[YearDataList.length - 1 - showYear].right}
              />
              <ArrowBorder
                tooltipBom={showBottom}
                tooltipYear={showYear}
                ArrowRight={contentDataList[YearDataList.length - 1 - showYear].right}
              />
            </Tooltips>
          )}
        </StatisticalDataList>
      </StatisticalDataBottom>
      <StaticImage alt='' src='../../images/about/about-bg2.png' layout='fullWidth' />
    </StatisticalDataTotal>
  )
}

export default StatisticalData
