import React, { useState, useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { LangType } from '../constant'
import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { graphql, useStaticQuery } from 'gatsby'
import {
  AboutPage,
  HeadBanner,
  HeadBannerContainer,
  HeaderBannerTitle,
  SecondBanner,
  AboutPageContainer,
} from '../style/pages/about.atom'
import { TitleBarEN, TitleBarCN } from '../components/about/title-barline.atom'
import {
  IntroduceDetail,
  Qualification,
  StatisticalData,
  TechnicalCapability,
  JoinUs,
  ContactUs,
  GatsbyImage,
} from '../components/about'

const JoinUsPage = () => {
  const intl = useIntl()
  const IsCN = intl.locale === LangType.CN
  const [isPhone, setPhone] = useState(false)
  useEffect(() => {
    setPhone(window.matchMedia('(max-width: 812px)').matches)
  }, [])

  const bgData = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "about/about-bg1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      bgmImage: file(relativePath: { eq: "about/about-bgm1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <Layout>
      <AboutPage>
        <Seo
          title={intl.formatMessage({ id: 'about.seo.title' })}
          description={intl.formatMessage({ id: 'about.seo.description' })}
          keywords={intl.formatMessage({ id: 'about.seo.keywords' })}
        />
        <AboutPageContainer>
          <HeadBanner>
            <GatsbyImage data={bgData} imageStyle='680px' imageClassName='background-image' />
            <div>
              <HeadBannerContainer>
                <HeaderBannerTitle IsCN={IsCN}>
                  {IsCN ? (
                    <h1>
                      RAYVISION
                      <br />
                      视觉云计算服务平台
                    </h1>
                  ) : (
                    <h1>
                      RAYVISION VISUAL
                      {!isPhone ? <br /> : ' '}
                      CLOUD COMPUTING {!isPhone && <br />}
                      PLATFORM
                    </h1>
                  )}

                  {IsCN ? <TitleBarCN fristBar={100} secondBar={200} /> : <TitleBarEN />}
                </HeaderBannerTitle>
              </HeadBannerContainer>
              <IntroduceDetail phoneModel={isPhone} IsCN={IsCN} />
            </div>
          </HeadBanner>
          <SecondBanner>
            <Qualification phoneModel={isPhone} IsCN={IsCN} />
            <StatisticalData phoneModel={isPhone} IsCN={IsCN} />
            <TechnicalCapability phoneModel={isPhone} IsCN={IsCN} />
            {IsCN && <JoinUs />}
            <ContactUs phoneModel={isPhone} IsCN={IsCN} />
          </SecondBanner>
        </AboutPageContainer>
      </AboutPage>
    </Layout>
  )
}
export default JoinUsPage
