import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-react-intl'
import {
  ContactUsTotal,
  ContactUsLeft,
  ContactUsRight,
  ContactUsTitle,
  ContactUsContainer,
  ContactUsList,
  ContactUsList1,
  ContanctUsContent,
} from './contact-us.atom'
import { TitleBarEN, TitleBarCN } from './title-barline.atom'

const contentList = [
  'about.contactUs_Content_Detail1_0',
  'about.contactUs_Content_Detail1_3',
  'about.contactUs_Content_Detail1_4',
  'about.contactUs_Content_Detail1_5',
]
const contentProductListCN = [
  {
    title: 'about.contactUs_SubTitle_2',
    content: ['about.contactUs_Content_Detail2_1', 'about.contactUs_Content_Detail2_2'],
  },
  {
    title: 'about.contactUs_SubTitle_3',
    content: ['about.contactUs_Content_Detail3_1', 'about.contactUs_Content_Detail3_2'],
  },
  {
    title: 'about.contactUs_SubTitle_4',
    content: ['about.contactUs_Content_Detail4_1', 'about.contactUs_Content_Detail4_2'],
  },
  {
    title: 'about.contactUs_SubTitle_9',
    content: ['about.contactUs_Content_Detail9_1', 'about.contactUs_Content_Detail9_2'],
  },
  // {
  //   title: 'about.contactUs_SubTitle_10',
  //   content: ['about.contactUs_Content_Detail10_1', 'about.contactUs_Content_Detail10_2'],
  // },
]
const contentLocalList = [
  {
    title: 'about.contactUs_SubTitle_6',
    content: [
      'about.contactUs_Content_Detail6_1',
      'about.contactUs_Content_Detail6_2',
      'about.contactUs_Content_Detail6_3',
    ],
  },
  {
    title: 'about.contactUs_SubTitle_7',
    content: [
      'about.contactUs_Content_Detail7_1',
      'about.contactUs_Content_Detail7_2',
      'about.contactUs_Content_Detail7_3',
    ],
  },
  {
    title: 'about.contactUs_SubTitle_8',
    content: [
      'about.contactUs_Content_Detail8_1',
      'about.contactUs_Content_Detail8_2',
      'about.contactUs_Content_Detail8_3',
    ],
  },
]
const ContactUs = ({ phoneModel = false, IsCN = true }) => {
  const contentProductList = IsCN ? contentProductListCN : []
  return (
    <ContactUsTotal IsCN={IsCN}>
      <ContactUsContainer>
        <ContactUsLeft>
          <ContactUsTitle>
            <FormattedMessage id='about.contactUsTitle' />
          </ContactUsTitle>
          {IsCN ? <TitleBarCN fristBar={40} secondBar={140} /> : <TitleBarEN />}
          {IsCN && (
            <ContanctUsContent>
              <FormattedMessage id='about.contactUs_SubTitle_1' />
              {contentList.map((item, index) => {
                return (
                  <span key={index}>
                    <FormattedMessage id={item} />
                  </span>
                )
              })}
            </ContanctUsContent>
          )}
        </ContactUsLeft>
      </ContactUsContainer>
      <ContactUsRight>
        <div>
          {contentProductList.map((item, index) => {
            return (
              <ContactUsList key={index}>
                <FormattedMessage id={item.title} />
                {item.content.map((subitem, subindex) => {
                  return (
                    <span key={subindex}>
                      <FormattedMessage id={subitem} />
                    </span>
                  )
                })}
              </ContactUsList>
            )
          })}
        </div>
        <div>
          {IsCN ? (
            contentLocalList.map((item, index) => {
              return (
                <ContactUsList1 key={index}>
                  <FormattedMessage id={item.title} />
                  {item.content.map((subitem, subindex) => {
                    return (
                      <span key={subindex}>
                        <FormattedMessage id={subitem} />
                      </span>
                    )
                  })}
                </ContactUsList1>
              )
            })
          ) : (
            <ContanctUsContent IsCN={IsCN}>
              <FormattedMessage id='about.contactUs_SubTitle_1' />
              {contentList.map((item, index) => {
                return (
                  <span key={index}>
                    <FormattedMessage id={item} />
                  </span>
                )
              })}
            </ContanctUsContent>
          )}
        </div>
      </ContactUsRight>
    </ContactUsTotal>
  )
}

export default ContactUs
