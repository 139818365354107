import { StaticImage } from 'gatsby-plugin-image'
import { FormattedMessage } from 'gatsby-plugin-react-intl'
import React from 'react'
import {
  AboutQualificationIcon10,
  AboutQualificationIcon7,
  AboutQualificationIcon8,
  AboutQualificationIcon9,
} from '../icons'
import {
  FlexWrapper,
  IconWrapper,
  SkillEn,
  TechnicalCapabilityContainer,
  TechnicalCapabilityLeft,
  TechnicalCapabilityRight,
  TechnicalCapabilityTitle,
  TechnicalCapabilityTotal,
  TechnicalSkillBlock,
  InfoWrapper,
} from './technical-capability.atom'
import { TitleBarCN, TitleBarEN } from './title-barline.atom'

const TechnicalCapability = ({ phoneModel = false, IsCN = true }) => {
  const technicalCapabilityList = [
    {
      icon: (
        <AboutQualificationIcon7
          width={phoneModel ? (IsCN ? '70px' : '25px') : IsCN ? '130px' : '79px'}
        />
      ),
      title: 'about.fourthSubTitle_1',
      content: 'about.fourthContent_1',
    },
    {
      icon: (
        <AboutQualificationIcon8
          width={phoneModel ? (IsCN ? '59px' : '22px') : IsCN ? '110px' : '66px'}
        />
      ),
      title: 'about.fourthSubTitle_2',
      content: 'about.fourthContent_2',
    },
    {
      icon: (
        <AboutQualificationIcon9
          width={phoneModel ? (IsCN ? '65px' : '24px') : IsCN ? '119px' : '64px'}
        />
      ),
      title: 'about.fourthSubTitle_3',
      content: 'about.fourthContent_3',
    },
    {
      icon: (
        <AboutQualificationIcon10
          width={phoneModel ? (IsCN ? '59px' : '18px') : IsCN ? '103px' : '55px'}
        />
      ),
      title: 'about.fourthSubTitle_4',
      content: 'about.fourthContent_4',
    },
  ]
  return (
    <TechnicalCapabilityTotal IsCN={IsCN}>
      <TechnicalCapabilityContainer>
        <TechnicalCapabilityLeft>
          <TechnicalCapabilityTitle phoneModel={phoneModel}>
            <FormattedMessage id='about.fourthTitle' />
          </TechnicalCapabilityTitle>
          {IsCN ? <TitleBarCN fristBar={40} secondBar={140} /> : <TitleBarEN />}
        </TechnicalCapabilityLeft>
      </TechnicalCapabilityContainer>
      <TechnicalCapabilityRight>
        {technicalCapabilityList.map((item, index) => {
          return (
            <TechnicalSkillBlock IsCN={IsCN} phoneModel={phoneModel} key={index}>
              <FlexWrapper IsCN={IsCN}>
                <IconWrapper IsCN={IsCN}>{item.icon}</IconWrapper>
                <InfoWrapper IsCN={IsCN}>
                  <h3>
                    <FormattedMessage id={item.title} />
                  </h3>
                  {IsCN && (
                    <p>
                      <FormattedMessage id={item.content} />
                    </p>
                  )}
                </InfoWrapper>
              </FlexWrapper>
              {!IsCN && (
                <SkillEn>
                  <FormattedMessage id={item.content} />
                </SkillEn>
              )}
            </TechnicalSkillBlock>
          )
        })}
      </TechnicalCapabilityRight>
      {!IsCN && (
        <div style={{ position: 'absolute', zIndex: -1, right: '0px', paddingTop: '127px' }}>
          <StaticImage alt='' src='../../images/about/about_bg3.png' />
        </div>
      )}
    </TechnicalCapabilityTotal>
  )
}

export default TechnicalCapability
