import styled from 'styled-components'
import { color, Media, spacing } from '../../style/theme'

export const TitleBar = styled.div`
  line-height: ${spacing.large};
  ::before {
    content: '';
    display: inline-block;
    height: 6px;
    margin-right: ${spacing.small};
    background-color: ${color.yellow};
  }
  ::after {
    content: '';
    display: inline-block;
    height: 6px;
    background-color: ${color.primaryDark};
  }
  ${Media.phone`
    line-height:7px;
    margin-top: 6px;
    ::before {
      height: 3px;
      margin-right:5px;
    }
    ::after {
      height: 3px;
    }
`}
`
export const TitleBarCN = styled(TitleBar)`
  ::before {
    width: ${(props) => props.fristBar}px;
  }
  ::after {
    width: ${(props) => props.secondBar}px;
  }
  ${Media.phone`
    line-height:7px;
    ::before {
      width:${(props) => props.fristBar / 2}px;
    }
    ::after {
      width: ${(props) => props.secondBar / 2}px;
    }
`}
`
export const TitleBarEN = styled(TitleBar)`
  ::before {
    width: 100px;
  }
  ::after {
    width: 150px;
  }
  ${Media.phone`
    ::before {
      width: 50px;
    }
    ::after {
      width: 75px;
    }
`}
`
