import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

export default function gatsbyImage({ data, imageStyle = 'auto', imageClassName = '' }) {
  const images = withArtDirection(getImage(data.bgImage), [
    {
      media: '(max-width: 812px)',
      image: getImage(data.bgmImage),
    },
  ])
  return <GatsbyImage image={images} className={imageClassName} style={{ height: imageStyle }} />
}
