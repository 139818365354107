import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-react-intl'
import {
  IntroduceDetailContent,
  IntroduceDetailContentTitle,
  IntroduceDetailContentList,
} from './introduce-detail.atom'

const contentListCN = [
  'about.headerContent_1',
  'about.headerContent_2',
  'about.headerContent_3',
  'about.headerContent_4',
]
const contentListEN = [
  'about.headerContent_1',
  'about.headerContent_2',
  'about.headerContent_3',
  'about.headerContent_4',
  'about.headerContent_5',
]

const IntroduceDetail = ({ phoneModel = false, IsCN = true }) => {
  const contentList = IsCN ? contentListCN : contentListEN
  return (
    <IntroduceDetailContent IsCN={IsCN}>
      <IntroduceDetailContentTitle IsCN={IsCN}>
        <h2>
          <FormattedMessage id='about.headerContentTitle' />
        </h2>
      </IntroduceDetailContentTitle>
      <IntroduceDetailContentList IsCN={IsCN}>
        {contentList.map((item, index) => {
          return (
            <div key={index}>
              <FormattedMessage id={item} /> <br />
              <br />
            </div>
          )
        })}
      </IntroduceDetailContentList>
    </IntroduceDetailContent>
  )
}

export default IntroduceDetail
